import { action, computed } from 'mobx';
import { BaseMobxStore } from 'proto/BaseMobxStore';

import { IFreightAdditionalServicesDrawerState } from 'monetary/models/states';
import { AdditionalRatesServiceDTM } from 'monetary/models/dtm/Quotas';
import { FreightFromStateDTM } from 'monetary/models/dtm';
import { ChargeCodeDesignation } from 'shipment-operations/constants';

export const initialState: IFreightAdditionalServicesDrawerState = {
  isLoading: false,
};

export class AdditionalServicesDrawerStore extends BaseMobxStore<IFreightAdditionalServicesDrawerState> {
  @action
  setIsLoading(isLoading: boolean) {
    this.state.isLoading = isLoading;
  }

  @action
  setAddAdditionalServicesDrawerServices(services: AdditionalRatesServiceDTM[] | undefined) {
    this.state.services = services;
  }

  @action
  setAddAdditionalServicesDrawerServicesChecked(servicesChecked: AdditionalRatesServiceDTM[] | undefined) {
    this.state.servicesChecked = servicesChecked;
  }

  @action
  setAddAdditionalServicesDrawerBookingRFQFormState(bookingRFQFormState: FreightFromStateDTM | undefined) {
    this.state.bookingRFQFormState = bookingRFQFormState;
  }

  @action
  setAddAdditionalServicesDrawerServicesBookingChecked(servicesBookingChecked: AdditionalRatesServiceDTM[] | undefined) {
    this.state.servicesBookingChecked = servicesBookingChecked;
  }

  @action
  setAddAdditionalServicesDrawerServicesGetQuote(servicesGetQuote: AdditionalRatesServiceDTM[] | undefined) {
    this.state.servicesGetQuote = servicesGetQuote;
  }

  @action
  setAddAdditionalServicesDrawerServicesBooking(servicesBooking: AdditionalRatesServiceDTM[] | undefined) {
    this.state.servicesBooking = servicesBooking;
  }

  @action
  setAddAdditionalServicesDrawerServicesCheckedIds(servicesCheckedIds: string[] | undefined) {
    this.state.servicesCheckedIds = servicesCheckedIds;
  }

  @action
  setAddAdditionalServicesDrawerServicesShipment(servicesShipment: AdditionalRatesServiceDTM[] | undefined) {
    this.state.servicesShipment = servicesShipment;
  }

  @action
  setAddAdditionalServicesDrawerShipmentId(shipmentId: string | undefined) {
    this.state.shipmentId = shipmentId;
  }

  @action
  setAddAdditionalServicesDrawerServicesShipmentChecked(servicesShipmentChecked: AdditionalRatesServiceDTM[] | undefined) {
    this.state.servicesShipmentChecked = servicesShipmentChecked;
  }

  @action
  setAddAdditionalServicesDrawerShipmentRFQFormState(shipmentRFQFormState: FreightFromStateDTM | undefined) {
    this.state.shipmentRFQFormState = shipmentRFQFormState;
  }

  @action
  setAddAdditionalServicesGoogleAutocompleteSessionToken(googleAutocompleteSessionToken: string | undefined) {
    this.state.googleAutocompleteSessionToken = googleAutocompleteSessionToken;
  }

  @computed
  get getAddAdditionalServicesOriginDrawer() {
    return this.state?.services?.filter((item) => item.designation === ChargeCodeDesignation.ORIGIN);
  }

  @computed
  get getAddAdditionalServicesFreightDrawer() {
    return this.state?.services?.filter((item) => item.designation === ChargeCodeDesignation.FREIGHT);
  }

  @computed
  get getAddAdditionalServicesDestinationDrawer() {
    return this.state?.services?.filter((item) => item.designation === ChargeCodeDesignation.DESTINATION);
  }

  @computed
  get getAddAdditionalServicesOriginBookingDrawer() {
    return this.state?.servicesBooking?.filter((item) => item.designation === ChargeCodeDesignation.ORIGIN);
  }

  @computed
  get getAddAdditionalServicesFreightBookingDrawer() {
    return this.state?.servicesBooking?.filter((item) => item.designation === ChargeCodeDesignation.FREIGHT);
  }

  @computed
  get getAddAdditionalServicesDestinationBookingDrawer() {
    return this.state?.servicesBooking?.filter((item) => item.designation === ChargeCodeDesignation.DESTINATION);
  }

  @computed
  get getAddAdditionalServicesDrawerTotalCost() {
    return this.state?.services?.reduce((prev, item) => {
      if (item.isChecked && item.countDocument && item.buyPrice) {
        return prev + (Number(item.countDocument) * Number(item.buyPrice));
      }

      return prev;
    }, 0);
  }

  @computed
  get getAddAdditionalServicesDrawerServicesGetQuoteTotalCost() {
    return this.state?.servicesGetQuote?.reduce((prev, item) => {
      if (item.isChecked && item.countDocument && item.buyPrice) {
        return prev + (Number(item.countDocument) * Number(item.buyPrice));
      }

      return prev;
    }, 0);
  }

  @computed
  get getAddAdditionalServicesBookingDrawerTotalCost() {
    return this.state?.servicesBooking?.reduce((prev, item) => {
      if (item.isChecked && item.countDocument && item.buyPrice) {
        return prev + (Number(item.countDocument) * Number(item.buyPrice));
      }

      return prev;
    }, 0);
  }

  @computed
  get getAddAdditionalServicesDrawerChecked() {
    return this.state?.services?.filter((item) => item.isChecked);
  }

  @computed
  get getAddAdditionalServicesBookingDrawerChecked() {
    return this.state?.servicesBooking?.filter((item) => item.isChecked);
  }

  @computed
  get getAddAdditionalServicesOriginShipmentDrawer() {
    return this.state?.servicesShipment?.filter((item) => item.designation === ChargeCodeDesignation.ORIGIN);
  }

  @computed
  get getAddAdditionalServicesFreightShipmentDrawer() {
    return this.state?.servicesShipment?.filter((item) => item.designation === ChargeCodeDesignation.FREIGHT);
  }

  @computed
  get getAddAdditionalServicesDestinationShipmentDrawer() {
    return this.state?.servicesShipment?.filter((item) => item.designation === ChargeCodeDesignation.DESTINATION);
  }

  @computed
  get getAddAdditionalServicesShipmentDrawerChecked() {
    return this.state?.servicesShipment?.filter((item) => item.isChecked);
  }

  @computed
  get getAddAdditionalServicesShipmentDrawerTotalCost() {
    return this.state?.servicesShipment?.reduce((prev, item) => {
      if (item.isChecked && item.countDocument && item.buyPrice) {
        return prev + (Number(item.countDocument) * Number(item.buyPrice));
      }

      return prev;
    }, 0);
  }
}

export const createAdditionalServicesDrawerStore = (_initialState?: IFreightAdditionalServicesDrawerState) => new AdditionalServicesDrawerStore(_initialState || initialState);
